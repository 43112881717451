<template>
  <button
    v-bind:class="{
      'programFilterButton': true,
      'programFilterButton--active': selectedFilter.value === value,
    }"
    v-on:click="$emit('click', { type, value })"
  >
    {{ name(data.name) }}
  </button>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('program', ['selectedFilter']),
  },

  methods: {
    name(name) {
      if (typeof name === 'object') {
        return name.mobile;
      }

      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programFilterButton {
  @include label;
  padding: 9px 14px;
  border-radius: 6px;
  background: $color-white;
  color: $color-text;

  &--active {
    background: $color-beige--dark;
  }
}
</style>