<template>
  <div class="programFilters">
    <div class="programFilters__mobile">
      <program-filter-button
        v-for="(filter, index) in overviewStatusFilters.options"
        v-bind:key="index"
        v-bind:data="filter"
        v-bind:type="overviewStatusFilters.type"
        v-bind:value="filter"
        v-on:click="onFilterChange"
        class="programFilters__mobileFilter"
      />

      <program-filter-select
        v-bind:data="overviewCategories"
        placeholder="Doel"
        v-model="selectedCategory"
        class="programFilters__mobileFilter"
      />

      <program-filter-select
        v-bind:data="overviewTags"
        placeholder="Trefwoord"
        v-model="selectedTag"
        class="programFilters__mobileFilter"
      />
    </div>

    <div class="programFilters__desktop">
      <program-filter-list
        v-bind:data="overviewStatusFilters"
        v-on:filterChanged="onFilterChange"
        class="programFilters__list"
      />

      <template v-if="overviewCategories.options.length">
        <div class="programFilters__title">
          {{ $t('programs.filtersGroupTitleGoal') }}
        </div>

        <program-filter-list
          v-bind:data="overviewCategories"
          v-on:filterChanged="onFilterChange"
          class="programFilters__list"
        />
      </template>

      <template v-if="overviewTags.options.length">
        <div class="programFilters__title">
          {{ $t('programs.filtersGroupTitleKeyword') }}
        </div>

        <program-filter-list
          v-bind:data="overviewTags"
          v-on:filterChanged="onFilterChange"
          class="programFilters__list"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProgramFilterButton from '@/components/program/ProgramFilterButton';
import ProgramFilterSelect from '@/components/program/ProgramFilterSelect';
import ProgramFilterList from '@/components/program/ProgramFilterList';

export default {
  components: {
    ProgramFilterButton,
    ProgramFilterSelect,
    ProgramFilterList,
  },

  data() {
    return {
      selectedCategory: null,
      selectedTag: null,
    };
  },

  computed: {
    ...mapState('program', ['selectedFilter']),
    ...mapGetters('program', ['overviewStatusFilters', 'overviewCategories', 'overviewTags']),
  },

  watch: {
    selectedCategory(value) {
      if (value) {
        this.$store.commit('program/setOverviewFilter', value);
      }
    },

    selectedTag(value) {
      if (value) {
        this.$store.commit('program/setOverviewFilter', value);
      }
    },
  },

  mounted() {
    this.selectedCategory = this.selectedFilter.type === 'category' ? this.selectedFilter.value : null;
    this.selectedTag = this.selectedFilter.type === 'tag' ? this.selectedFilter.value : null;
  },

  methods: {
    onFilterChange(selectedFilter) {
      this.$store.commit('program/setOverviewFilter', selectedFilter);

      this.selectedCategory = selectedFilter.type === 'category' ? selectedFilter : null;
      this.selectedTag = selectedFilter.type === 'tag' ? selectedFilter : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programFilters__mobile {
  @include desktop {
    display: none;
  }
}

.programFilters__mobileFilter {
  margin: 0 6px 6px 0;
}

.programFilters__desktop {
  display: none;

  @include desktop {
    display: block;
  }
}

.programFilters__list {
  margin: 0 0 rem(40px) 0;
}

.programFilters__title {
  @include tiny--bold;
  margin: 0 0 rem(12px) 0;
  text-transform: uppercase;
  color: #a19883;
}
</style>