<template>
  <div class="programFilterSelect">
    <select
      v-bind:class="{
        'programFilterSelect__field': true,
        'programFilterSelect__field--active': selectedFilter.value === fieldValue,
      }"
    v-model="fieldValue"
    v-on:change="updateInput"
    >
      <option v-bind:value="null">
        {{ placeholder }}
      </option>

      <option
        v-for="(option, index) in data.options"
        v-bind:key="index"
        v-bind:value="option"
      >
        {{ option.name }}
      </option>
    </select>

    <font-awesome-icon
      icon="chevron-down"
      class="programFilterSelect__icon"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    placeholder: {
      type: String,
      required: true,
    },

    modelValue: {
      type: null, // any type
    },
  },

  data() {
    return {
      fieldValue: this.modelValue,
    };
  },

  computed: {
    ...mapState('program', ['selectedFilter']),
  },

  watch: {
    value(value) {
      this.fieldValue = value ? value.value : null;
    }
  },

  methods: {
    updateInput() {
      this.$emit('update:modelValue', {
        type: this.data.type,
        value: this.fieldValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programFilterSelect {
  display: inline-block;
  position: relative;
}

.programFilterSelect__field {
  @include label;
  padding: 9px 32px 9px 14px;
  border: 0;
  border-radius: 6px;
  background: $color-white;
  color: $color-text;
  appearance: none;
  outline: none;

  &--active {
    background: $color-beige--dark;
  }
}

.programFilterSelect__icon {
  position: absolute;
  top: rem(14px);
  right: rem(16px);
  font-size: rem(11px);
  color: rgba($color-text, 0.6);
}
</style>