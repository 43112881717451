<template>
  <div class="programPage">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <h1 class="programPage__title">
              {{ $t('programs.pageTitle') }}
            </h1>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column modifiers="sm-4 md-3">
            <program-filters />
          </base-column>

          <base-column modifiers="sm-8 md-9">
            <template v-if="isLoading">
              <div class="programPage__programs">
                <skeleton-loader-card
                  v-for="n in numSkeletonLoaders"
                  v-bind:key="`l${n}`"
                  v-bind:show-title="false"
                  image-height="350px"
                  class="programPage__program programPage__program--loader"
                />
              </div>
            </template>

            <div class="programPage__programs">
              <router-link
                v-for="program in programsByFilter"
                v-bind:key="program.id"
                v-bind:to="createProgramLink(program)"
                class="programPage__program"
              >
                <programs-card v-bind:program="program" />
              </router-link>
            </div>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import ProgramFilters from '@/components/program/ProgramFilters';
import ProgramsCard from '@/components/program/ProgramsCard';
import SkeletonLoaderCard from '@/components/SkeletonLoaderCard';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    ProgramFilters,
    SkeletonLoaderCard,
    ProgramsCard,
  },

  data() {
    return {
      numSkeletonLoaders: 6,
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters('program', ['programsByFilter']),
  },

  async created() {
    await this.$store.dispatch('program/fetchAll');
    this.isLoading = false;
  },

  methods: {
    createProgramLink({ active_period: periodId, id: programId }) {
      if (periodId) {
        return {
          name: 'programWorkout',
          params: { programId, periodId },
        };
      }

      return {
        name: 'programIntro',
        params: { programId },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programPage {
  margin: rem(20px) 0 0 0;
}

.programPage__title {
  @include heading-2;

  @include desktop {
    margin: 0 0 rem(25px) 0;
  }
}

.programPage__programs {
  @include desktop {
    display: flex;
    flex-wrap: wrap;
  }
}

.programPage__program {
  display: block;
  margin: 0 0 10px 0;

  @include desktop {
    flex: 0 0 auto;
    margin: 0 15px 15px 0;
    width: calc(33.3% - 10px);
    cursor: pointer;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &--loader {
    --skeleton-loader-bg-color: #fff;
    --skeleton-loader-bg-color-mid: #f0ede6;
  }
}
</style>