<template>
  <ul class="programFilterList">
    <li
      v-for="(option, index) in data.options"
      v-bind:key="index"
      v-bind:class="{
        'programFilterList__item': true,
        [`programFilterList__item--${data.type}`]: true,
        'programFilterList__item--active': selectedFilter.type === data.type && selectedFilter.value.slug === option.slug,
      }"
    >
      <span class="programFilterList__itemStatus" />

      <span
        class="programFilterList__itemLabel"
        v-on:click="$emit('filterChanged', {
          type: data.type,
          value: option,
        })"
      >
        {{ name(option.name) }}
      </span>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
import capitalize from '@/filters/capitalize';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('program', ['selectedFilter']),
  },

  methods: {
    name(name) {
      if (typeof name === 'object') {
        return capitalize(name.desktop);
      }

      return capitalize(name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programFilterList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.programFilterList__item {
  display: flex;
  align-items: center;
  margin: 0 0 rem(8px) 0;

  &:last-child {
    margin: 0;
  }

  &--status {
    @include lead--bold;
  }

  &--category {
    @include paragraph--bold;
  }

  &--tag {
    @include small--bold;
  }
}

.programFilterList__itemStatus {
  display: none;
  margin: 0 rem(8px) 0 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $color-yellow;

  .programFilterList__item--active & {
    display: inline-block;
  }
}

.programFilterList__itemLabel {
  cursor: pointer;
}
</style>