<template>
  <div class="programsCard">
    <div
      class="programsCard__image programsCard__image--mobile"
      v-bind:style="{ 'background-image': `url(${program.image_mobile}?w=750&q=80)` }"
    />

    <div
      class="programsCard__image programsCard__image--desktop"
      v-bind:style="{ 'background-image': `url(${program.image_vertical}?w=500&q=80)` }"
    />

    <div class="programsCard__content">
      <div
        v-if="program.categories.length"
        class="programsCard__category"
      >
        {{ program.categories[0].name }}
      </div>

      <div class="programsCard__title">
        {{ program.name }}
      </div>

      <div
        v-if="program.tags.length"
        class="programsCard__tags"
      >
        {{ tags }}
      </div>

      <base-button
        modifiers="white small block fullWidth"
        class="programsCard__button"
      >
        {{ buttonLabel }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseButton,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },
  },

  computed: {
    tags() {
      return this.program.tags.reduce((acc, { name }, index) => {
        const separator = '·';

        return `${acc} ${index !== 0 ? separator : ''} ${name}`;
      }, '');
    },

    buttonLabel() {
      if (this.program.active_period) {
        return this.$t('programs.activeProgramButtonLabel');
      }

      return this.$t('programs.inactiveProgramButtonLabel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programsCard {
  position: relative;
  min-height: 240px;
  border-radius: 10px;
  overflow: hidden;

  @include desktop {
    height: 405px;
  }
}

.programsCard__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: scale(1);
  transition: transform 0.35s;

  &--mobile {
    @include desktop {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include desktop {
      display: block;
    }
  }

  .programsCard:hover & {
    transform: scale(1.05);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(#000, 0.8) 97%);
    transition: background 0.35s;

    .programsCard:hover & {
      background-color: rgba(#000, 0.5);
    }
  }
}

.programsCard__content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 15px;
  width: 100%;
  background: $color-white;

  @include desktop {
    padding: 16px;
    background: none;
    transform: translateY(rem(54px));
    transition: transform 0.35s;

    .programsCard:hover & {
      transform: translateY(0);
    }
  }
}

.programsCard__category {
  @include tiny--bold;
  display: none;
  margin: 0 0 rem(3px) 0;
  text-transform: uppercase;
  color: $color-white;

  @include desktop {
    display: block;
  }
}

.programsCard__title {
  @include paragraph--bold;

  @include desktop {
    @include heading-4;
    margin: 0 0 rem(6px) 0;
    color: $color-white;
  }
}

.programsCard__tags {
  @include note;

  @include desktop {
    @include label;
    color: $color-white;
  }
}

.programsCard__button {
  display: none;

  @include desktop {
    display: block;
    margin: rem(16px) 0 0 0;
  }
}
</style>
